ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
}

li {
    float: left;
}

li a{
    display: block;
    color: aliceblue;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

li a:hover {
    color: #EE80A8;
}

.siteLanguage {
    float: right;
}