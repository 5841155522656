footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #FFFFFF;
    text-align: center;
}

.social-icon {
    position: sticky;
    font-size: 40px;
    text-align: center;
    margin: 10px 10px;
    color: #fff;
}

.facebook:hover {color:#3b5999;}
.instagram:hover {color: #55acee;}
.github:hover {color: #302f2f;}
.linkedin:hover {color: #0077B5;}